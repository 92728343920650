import { isEmpty } from "lodash";
import { useState } from "react";
import titleCase from "title-case";
import {
  applyRegexLetters,
  applyRegexName,
  applyRegexNumbers,
  formatCurrency,
  unformatCurrency,
} from "../components/tools";

const invalidSubmitTools = (values, woBooleanChecker) => {
  const keys = Object.keys(values) || [];
  let arr = [];
  keys.map((key) => {
    const value = values[key];
    const isBoolean = typeof value == "boolean";

    const atomicPush = () => {
      const emptyObj = typeof value == "object" && isEmpty(value);
      if (emptyObj) return;
      return arr.push(key);
    };

    if (woBooleanChecker) {
      if (value) return atomicPush();
      return;
    }

    if (isBoolean || value) {
      if (isBoolean) return arr.push(key);
      return atomicPush();
    }
  });
  const result = Boolean(keys.filter((key) => !arr.includes(key)).length);
  return result;
};

export const useFormInvalid = (array = []) => {
  // array example
  // const array = [
  //   {name: 'bank', maxLength: 10, label: 'Bank', isText: true},
  //   {name: 'hp', maxLength: 10, label: 'hp', isNumber: true},
  //   {name: 'laptop', maxLength: 10, label: 'laptop'},
  //   {name: 'minimum', minLength: 10, label: 'laptop'},
  //   {name: 'amount', maxAmount: 1000000, minAmount:10000, woName:true},
  // ];
  let obj = {};
  array.map(({ name }) => (obj[name] = ""));

  const [errorMsg, setErrorMsg] = useState(obj);

  const filteredArray = (key) => array.filter((item) => item[key]);

  const regexChecker = (values) => {
    const pleaseUseAz = "Please use alphabet (A-Z)";

    const pleaseUseNumber = "Please use numbers (0-9)";

    const pleaseUseName =
      "Please only fill with alphabet (A-Z) and these special characters (.) (,) (-) (')";

    const alphabetArr = filteredArray("isText");
    const numberArr = filteredArray("isNumber");
    const nameArr = filteredArray("isName");

    const maxLengthArr = filteredArray("maxLength");
    const minLengthArr = filteredArray("minLength");

    const minAmountArr = filteredArray("minAmount");
    const maxAmountArr = filteredArray("maxAmount");

    let obj = {};

    // functions
    const invalidValueFunc = (name, label, woName) => {
      const text = label || titleCase(name);
      const completeText = woName
        ? "Cannot be empty"
        : `${text} cannot be empty`;
      obj[name] = completeText;
    };

    const regex = ({ array, regexType, message }) => {
      array.map(({ name, label, woName }) => {
        // run this in every functions
        const key = name;
        const value = values[key];

        if (!value) return invalidValueFunc(name, label, woName);
        // run this in every functions
        const invalid = regexType(String(value));

        if (invalid) obj[key] = message;
      });
    };

    const lengthChecker = ({ array }) => {
      array.map(({ name, label, maxLength, minLength, woName }) => {
        // run this in every functions
        const key = name;
        const value = values[key];

        if (!value) return invalidValueFunc(name, label, woName);
        // run this in every functions

        const invalidMax = String(value).length > maxLength;
        const invalidMin = String(value).length < minLength;

        if (invalidMax && maxLength)
          return (obj[key] = `Cannot be longer than ${maxLength} characters`);

        if (invalidMin && minLength)
          return (obj[key] = `Minimum ${minLength} characters`);
      });
    };

    const amountChecker = ({ array }) => {
      array.map(({ name, label, minAmount, maxAmount, woName, errorMsg }) => {
        // run this in every functions
        const key = name;
        const value = values[key];

        if (!value) return invalidValueFunc(name, label, woName);
        // run this in every functions
        const getNumberValue = Number(value)
          ? Number(value)
          : Number(unformatCurrency(value));

        const invalidMinAmount = getNumberValue <= minAmount;
        const invalidMaxAmount = getNumberValue >= maxAmount;

        const defaultText = `amount is ${formatCurrency(minAmount)}`;

        const minAmountMsg = errorMsg ? errorMsg : `Minimum ${defaultText}`;
        const maxAmountMsg = errorMsg ? errorMsg : `Maximum ${defaultText}`;

        if (invalidMinAmount && minAmount) return (obj[key] = minAmountMsg);

        if (invalidMaxAmount && maxAmount) return (obj[key] = maxAmountMsg);
      });
    };
    // functions

    // call functions

    regex({
      array: alphabetArr,
      message: pleaseUseAz,
      regexType: applyRegexLetters,
    });

    regex({
      array: numberArr,
      message: pleaseUseNumber,
      regexType: applyRegexNumbers,
    });

    regex({
      array: nameArr,
      message: pleaseUseName,
      regexType: applyRegexName,
    });

    lengthChecker({ array: maxLengthArr });

    lengthChecker({ array: minLengthArr });

    amountChecker({ array: minAmountArr });

    amountChecker({ array: maxAmountArr });
    // call functions

    setErrorMsg(obj);
    const invalid = Object.keys(obj).length;
    return invalid;
  };

  const invalidSubmit = (values, onlyCheckRegex = false) => {
    const checkedByRegex = regexChecker(values);
    const checkedByTools = invalidSubmitTools(values);
    if (onlyCheckRegex) return checkedByRegex;
    return checkedByRegex || checkedByTools;
  };

  return { invalidSubmit, errorMsg, setErrorMsg };
};
