import { get } from "lodash";
import { useContext, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { ToasterContext } from "../../contexts/ToasterContext";
import { apiBusiness } from "../../tools/api";
import { eventsTracker } from "../../universalFunctions/events";

export const useLoginHook = () => {
  const [loading, setLoading] = useState(false);

  const { authorize } = useContext(AuthContext);
  const { errorToasterApi } = useContext(ToasterContext);

  const login = async (val, whenError) => {
    try {
      setLoading(true);
      const { data } = await apiBusiness.post("/authenticate", val);
      const token = get(data, "auth_token");
      const { email } = val;
      await authorize({ token, user: { email } });
      eventsTracker("login", { email });
    } catch (error) {
      errorToasterApi(error, 3000, true);
      typeof whenError == "function" && whenError();
    } finally {
      setLoading(false);
    }
  };
  return { loading, login };
};
