import React, { useEffect, useState } from "react";
import { colors } from "../../../assets/colors";
import CheckEmailIcon from "../../../assets/images/check-email-icon.svg";
import {
  GothamMedium,
  GothamRegular,
  TypeFezNeueMedium,
} from "../../../components/Text";
import { windowDimension } from "../../../components/tools";

export const SuccessRegister = ({
  onClick,
  email,
  text = "For verification purpose, we have sent an email to",
}) => {
  const [timer, setTimer] = useState(null);
  const [timerSwitch, setTimerSwitch] = useState(false);

  useEffect(() => {
    if (timer === 0) {
      setTimer(null);
      setTimerSwitch(false);
      return;
    }

    if (timerSwitch) {
      const intervalId = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [timer]);

  // Timer starter when sending email again
  const startTimer = () => {
    setTimer(30);
    setTimerSwitch(true);
  };

  const handleClick = () => {
    startTimer();
    onClick();
  };
  const { isTabOrPhone } = windowDimension();
  const style = isTabOrPhone
    ? { border: "1px solid #eaeaea", padding: 16, borderRadius: 20 }
    : { position: "absolute", top: 30, left: 0 };

  return (
    <div style={style}>
      {!isTabOrPhone && (
        <>
          <img
            src={CheckEmailIcon}
            style={{ cursor: "pointer", marginTop: 20, marginBottom: 20 }}
          />
          <TypeFezNeueMedium
            style={{
              color: colors.blue10,
              fontSize: 24,
              lineHeight: "32px",
              marginBottom: 8,
            }}
          >
            Please check your email
          </TypeFezNeueMedium>
        </>
      )}
      <GothamRegular
        style={{
          fontSize: 14,
          lineHeight: "24px",
        }}
      >
        {text}
      </GothamRegular>
      <GothamMedium
        style={{
          fontSize: 14,
          lineHeight: "24px",
          fontWeight: "bold",
          color: colors.primaryBlue,
        }}
      >
        {email}
      </GothamMedium>
      <GothamRegular
        style={{
          fontSize: 14,
          lineHeight: "24px",
        }}
      >
        Please open your email and follow the next instruction
      </GothamRegular>
      <GothamRegular style={{ marginTop: 26, color: colors.grey25 }}>
        Don't get the email?
        {timerSwitch ? (
          <span
            style={{
              marginLeft: 5,
              fontWeight: "bold",
              textDecoration: "none",
              fontFamily: "GothamBook",
              fontSize: 14,
              color: colors.greyC2,
            }}
          >
            Send again ({timer}s)
          </span>
        ) : (
          <span
            className="link"
            style={{
              marginLeft: 5,
              fontWeight: "bold",
              textDecoration: "none",
              fontFamily: "GothamBook",
              fontSize: 14,
            }}
            onClick={handleClick}
          >
            Send again
          </span>
        )}
      </GothamRegular>
    </div>
  );
};
