import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import loginHelp from "../../assets/images/login-big-help.svg";
import loginMax from "../../assets/images/login-big-maximum.svg";
import loginStart from "../../assets/images/login-big-startup.svg";
import { GothamRegular } from "../../components/Text";
import { windowDimension } from "../../components/tools";

export const LoginCarousel = () => {
  const { height } = windowDimension();

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dotsClass: "button__bar",
  };

  const dataSlider = [
    {
      img: loginMax,
      title: "Maximum flexibility with no compromises",
      desc: "Integrates all your payment needs in one platform. No more hassle.",
    },
    {
      img: loginStart,
      title: "Made for startups and SMEs",
      desc: "Process your vendor payments, buy inventories, payments for recurring SaaS subscriptions, and online billing.",
    },
    {
      img: loginHelp,
      title: "Always ready to help",
      desc: "24/7 support from your dedicated Account Manager – get help from real humans whenever you need it.",
    },
  ];
  return (
    <div
      style={{
        background: "linear-gradient(143.24deg, #102C57 21.38%, #486896 100%)",
        height,
      }}
    >
      <CustomSlider {...settings}>
        {dataSlider?.map((item, index) => {
          const { img, title, desc } = item || {};
          return (
            <ImageCarousel key={index}>
              <img src={img} />
              <h2
                style={{
                  color: "white",
                  fontSize: 28,
                  marginTop: 36,
                  fontFamily: "HalenoirExpandedBlack",
                }}
              >
                {title}
              </h2>
              <GothamRegular style={{ color: "white", marginBottom: 70 }}>
                {desc}
              </GothamRegular>
            </ImageCarousel>
          );
        })}
      </CustomSlider>
    </div>
  );
};

const ImageCarousel = styled.div`
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
  padding: 0px 100px;
  @media (max-width: 768px) {
    padding: 0px;
  }
`;

const CustomSlider = styled(Slider)`
  top: 15%;
  bottom: 25%;
`;
