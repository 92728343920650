import { useState } from "react";
import { ToasterHook } from "../../../contexts/ToasterContext";
import { useMutation } from "../../../tools/api";
import { eventsTracker } from "../../../universalFunctions/events";
import { SuccessRegister } from "../register/success";
import { DefaultForgotPassword } from "./default";

export const ForgotPasswordForm = ({ toSignIn }) => {
  const [email, setEmail] = useState(false);

  const { successToaster } = ToasterHook();

  const afterSuccess = (_, payload) => {
    const { email } = payload || {};
    successToaster({
      msg: "Email has been sent, please check your email inbox",
    });
    eventsTracker(email ? "resend_forgot_password" : "forgot_password", {
      email,
    });
    setEmail(email);
  };

  const { loading, mutation } = useMutation({
    url: "/business_users/forgot_password",
    afterSuccess,
    method: "post",
  });

  if (email)
    return (
      <SuccessRegister
        email={email}
        onClick={() => mutation({ email })}
        text="We have sent email to"
      />
    );

  return (
    <DefaultForgotPassword
      loading={loading}
      onSubmit={mutation}
      toSignIn={toSignIn}
    />
  );
};
