import { title, titleCase } from "change-case";
import React, { useEffect, useState } from "react";
import { colors } from "../../assets/colors";
import { CustomButton } from "../../components/Buttons";
import { GothamMedium, GothamRegular } from "../../components/Text";
import { isProduction } from "../../components/tools";
import { ToasterHook } from "../../contexts/ToasterContext";
import { CenteredModal } from "../../modals/centeredComponents";

const useLocalStorage = (name) => {
  const [value, setValue] = useState("");

  useEffect(() => setValue(localStorage.getItem(value)), []);

  const setsValue = (value) => {
    setValue(value);
    localStorage.setItem(name, value);
  };

  return { value, setValue: setsValue };
};

export const EnvModal = ({ isOpen, toggle }) => {
  if (isProduction) return null;

  const array = [
    "original",
    "production",
    "internal_production",
    "internal_production_2",
    "sandbox",
    "staging",
  ];

  const { successToaster } = ToasterHook();
  const { value, setValue } = useLocalStorage("env_type");

  return (
    <CenteredModal isOpen={isOpen}>
      <GothamMedium className="font20" style={{ marginBottom: 16 }}>
        Environment Menu
      </GothamMedium>
      <GothamRegular style={{ marginBottom: 16 }}>
        Please select which environment you want to test
      </GothamRegular>
      {array.map((string, index) => {
        const isActiveDecider = () => {
          if (!value) return !index;
          return value == string;
        };

        const isActive = isActiveDecider();

        const color = isActive ? colors.pink : colors.greyBB;

        const isOriginal = value == "original";

        const handleClick = () => {
          if (isActive) return;
          setValue(isOriginal ? "" : string);
        };
        return (
          <div
            onClick={handleClick}
            key={index}
            style={{
              border: `1px solid ${color}`,
              marginBottom: 12,
              padding: 12,
              borderRadius: 8,
            }}
            className="d-flex justify-content-between align-items-center pinkhover"
          >
            <GothamMedium
              style={{
                color: color,
              }}
            >
              {titleCase(string)}
            </GothamMedium>
            <div
              style={{
                backgroundColor: color,
                height: 8,
                width: 8,
                borderRadius: 8,
              }}
            />
          </div>
        );
      })}
      <CustomButton
        className="w-100"
        onClick={() => {
          toggle();
          successToaster({
            msg: `ENV successfully changed to ${titleCase(
              value || "original"
            )}!`,
          });
        }}
      >
        Confirm
      </CustomButton>
    </CenteredModal>
  );
};
