import { useForm } from "react-hook-form";
import { Col } from "reactstrap";
import { colors } from "../../../assets/colors";
import { CustomButton } from "../../../components/Buttons";
import { CustomTextField } from "../../../components/inputs";
import { GothamMedium, GothamRegular } from "../../../components/Text";
import * as yup from "yup";
import { schemaGenerator, windowDimension } from "../../../components/tools";

export const DefaultForgotPassword = ({ toSignIn, onSubmit, loading }) => {
  const { isTabOrPhone } = windowDimension();
  const resolver = schemaGenerator({
    stringArr: [
      {
        name: "email",
        yup: yup.string().email().required(),
      },
    ],
  });

  const useFormObj = useForm({ resolver });
  const { handleSubmit } = useFormObj;

  return (
    <div className="d-flex align-items-center w-100" style={{ height: "100%" }}>
      <div className="w-100">
        {!isTabOrPhone && (
          <>
            <GothamMedium
              style={{
                color: colors.grey25,
                fontSize: 24,
                lineHeight: "32px",
                marginBottom: 12,
              }}
            >
              Forgot Password
            </GothamMedium>
            <GothamRegular
              style={{
                marginBottom: 24,
              }}
            >
              Forgot your password? Enter your registered email and we will send
              you a link to reset your password
            </GothamRegular>
          </>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <CustomTextField name="email" useFormObj={useFormObj} />
          <CustomButton
            type="submit"
            isLoading={loading}
            style={{
              width: "100%",
            }}
          >
            Forgot Password
          </CustomButton>
        </form>
        <Col style={{ marginLeft: 0, paddingLeft: 0 }}>
          <GothamRegular style={{ marginTop: 26, color: colors.grey25 }}>
            Remember your password?
            <span
              className="link"
              style={{
                marginLeft: 5,
                fontWeight: "bold",
                textDecoration: "none",
                fontFamily: "GothamBook",
                fontSize: 14,
              }}
              onClick={() => toSignIn && toSignIn()}
            >
              Sign In
            </span>
          </GothamRegular>
        </Col>
      </div>
    </div>
  );
};
