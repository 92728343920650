import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Col } from "reactstrap";
import { colors } from "../../../assets/colors";
import caretRightSvg from "../../../assets/images/caret-right-white-icon.svg";
import { phoneCode, phoneSimpleFormatter } from "../../../assets/phoneCode";
import { CustomButton } from "../../../components/Buttons";
import { CustomTextField, SelectionForm } from "../../../components/inputs";
import { useModalHook } from "../../../components/Modals";
import {
  GothamMedium,
  GothamRegular,
  TypeFezNeueMedium,
} from "../../../components/Text";
import {
  schemaGenerator,
  submitToLabelValue,
  windowDimension,
} from "../../../components/tools";
import { ToasterHook } from "../../../contexts/ToasterContext";
import { fetch, useMutation } from "../../../tools/api";
import { eventsTracker } from "../../../universalFunctions/events";
import { useFormInvalid } from "../../../universalFunctions/formFunctions";
import { Agreement } from "./components";
import { SuccessRegister } from "./success";

const formatter = (val) => {
  const values = submitToLabelValue(val);
  const {
    business_industry: industry,
    business_name: name,
    mobile,
    mobile_country_iso,
    ...rest
  } = values;

  const country_code = phoneCode.filter(
    ({ code }) => code == mobile_country_iso
  )[0]?.value;

  const user = {
    ...rest,
    is_agreed: true,
    mobile,
    mobile_country_iso,
    country_code,
  };
  const result = {
    user,
    business: { industry, name },
  };
  return result;
};

export const RegisterForm = ({ toSignIn }) => {
  const { isTabOrPhone } = windowDimension();

  // special case just for name
  const { errorMsg, invalidSubmit } = useFormInvalid([
    { name: "name", isName: "true", maxLength: 50, label: "Full name" },
  ]);
  // special case just for name

  const resolver = schemaGenerator({
    stringArr: ["name", "email", "password", "business_name"],
    numbersArr: ["mobile"],
    objectArr: ["mobile_country_iso", "business_industry"],
  });

  const useFormObj = useForm({
    resolver,
    defaultValues: { mobile_country_iso: "ID" },
  });

  const { query } = useRouter();

  const { errorToaster } = ToasterHook();

  useEffect(() => {
    if (query?.email) {
      toggleSuccess();
      errorToaster("Error!", "Please check your email");
    }
  }, [query]);

  const { handleSubmit } = useFormObj;

  const { isOpen: isChecked, toggle } = useModalHook();
  const { isOpen: isSuccess, toggle: toggleSuccess } = useModalHook();
  const [email, setEmail] = useState("");

  const afterSuccess = (_, payload) => {
    const { user, business } = payload || {};
    const { password, is_agreed, email, ...eventsObj } = user || {};

    eventsTracker("register", { business, user: { ...eventsObj, email } });

    setEmail(email);
    toggleSuccess();
  };

  const { mutation, loading } = useMutation({
    url: "/business_partners",
    afterSuccess,
    method: "post",
  });

  const submit = (val) => {
    const invalid = invalidSubmit(val);
    if (invalid) return;
    const result = formatter(val);
    mutation(result);
  };

  const { data: industryOptions, loading: industryOptionsLoading } = fetch({
    url: "/industry_list",
    formatter: (res) => {
      const result = (res?.data || []).map((item) => {
        const { name } = item;
        return { ...item, label: name, value: name };
      });
      return result;
    },
  });

  const afterSuccessResend = (_, payload) => {
    const { email } = payload || {};
    eventsTracker("resend_register", { email });
  };

  const { mutation: resendEmailMutation } = useMutation({
    url: "/business_users/resend_confirmation",
    method: "post",
    afterSuccess: afterSuccessResend,
  });

  const resendEmail = () => {
    const payload = {
      email: email || query?.email,
    };

    resendEmailMutation(payload);
  };

  return (
    <div>
      {isSuccess ? (
        <SuccessRegister email={email || query?.email} onClick={resendEmail} />
      ) : (
        <>
          {!isTabOrPhone && (
            <TypeFezNeueMedium
              style={{
                color: colors.blue10,
                marginBottom: 24,
              }}
              className="font24"
            >
              Create an account
            </TypeFezNeueMedium>
          )}
          <form onSubmit={handleSubmit(submit)}>
            <CustomTextField
              name="name"
              label="Your Full Legal Name"
              useFormObj={useFormObj}
              error={errorMsg?.name}
            />

            <CustomTextField
              label="Your Email"
              name="email"
              type="email"
              useFormObj={useFormObj}
            />

            <div className="d-flex justify-content-between">
              <div style={{ width: 100 }}>
                <SelectionForm
                  isPhoneSimple
                  name="mobile_country_iso"
                  label="Code"
                  useFormObj={useFormObj}
                  options={phoneSimpleFormatter(phoneCode)}
                />
              </div>

              <div style={{ width: "calc(100% - 112px)" }}>
                <CustomTextField
                  name="mobile"
                  label={isTabOrPhone ? "Phone Number" : "Your Phone Number"}
                  useFormObj={useFormObj}
                />
              </div>
            </div>

            <CustomTextField
              name="password"
              label="Password"
              type="password"
              useFormObj={useFormObj}
            />

            <TypeFezNeueMedium
              style={{
                color: colors.grey25,
                fontSize: 14,
                lineHeight: "22px",
                marginBottom: 16,
              }}
            >
              Tell us about your company
            </TypeFezNeueMedium>

            <CustomTextField
              label="Registered Company Name"
              name="business_name"
              useFormObj={useFormObj}
              containerStyle={{ width: "100%" }}
            />

            <SelectionForm
              name="business_industry"
              useFormObj={useFormObj}
              options={industryOptions}
              isLoading={industryOptionsLoading}
              label="Industry"
            />

            <div className="d-flex align-items-center my-4 ">
              <Agreement toggle={toggle} isChecked={isChecked} />
            </div>

            <CustomButton
              isLoading={loading}
              disabled={!isChecked}
              type="submit"
              style={{
                width: "100%",
              }}
              rightIcon={
                <img src={caretRightSvg} style={{ width: 20, marginLeft: 8 }} />
              }
            >
              Register Now
            </CustomButton>
          </form>

          <Col style={{ marginLeft: 0, paddingLeft: 0 }}>
            <GothamRegular style={{ marginTop: 26, color: colors.grey25 }}>
              Already have an account?
              <span
                className="link"
                style={{
                  marginLeft: 5,
                  fontWeight: "bold",
                  textDecoration: "none",
                  fontFamily: "GothamBook",
                  fontSize: 14,
                }}
                onClick={() => toSignIn && toSignIn()}
              >
                Sign In
              </span>
            </GothamRegular>
          </Col>
        </>
      )}
    </div>
  );
};
