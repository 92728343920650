import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import newLogo from "../../assets/images/new-logo-transfez.svg";
import { useModalHook } from "../../components/Modals";
import { windowDimension } from "../../components/tools";
import { eventsTracker } from "../../universalFunctions/events";
import { LoginCarousel } from "./components";
import { EnvModal } from "./envModal";
import { ForgotPasswordForm } from "./forgotPassword/parent";
import { LoginForm } from "./login";
import { RegisterForm } from "./register/parent";

const CurrentScreenDecider = () => {
  const [currentScreen, setCurrentScreen] = useState("login");
  const isForgotPassword = currentScreen == "forgotPassword";
  const isRegister = currentScreen == "register";

  const { query, pathname, push } = useRouter();

  useEffect(() => {
    if (pathname == "/register") return setCurrentScreen("register");
  }, [pathname]);

  const { email } = query;

  const toSignIn = () => {
    eventsTracker("login_to_sign_in");
    push("/login");
    setCurrentScreen("login");
  };

  const toRegister = () => {
    eventsTracker("login_to_register");
    push("/register");
    // setCurrentScreen("register");
  };

  const toForgotPassword = () => {
    eventsTracker("login_to_forgot_password");
    setCurrentScreen("forgotPassword");
  };

  useEffect(() => {
    if (!email) return;
    toRegister();
  }, [email]);

  const props = { toSignIn, toRegister, toForgotPassword };

  if (isForgotPassword) return <ForgotPasswordForm {...props} />;
  if (isRegister) return <RegisterForm {...props} />;

  return <LoginForm {...props} />;
};

export const LoginPageComponents = ({ children }) => {
  const { height, width, isTabOrPhone, isSmall } = windowDimension();
  const isLaptop = height <= 820;

  const [counter, setCounter] = useState(0);
  const { isOpen, toggle } = useModalHook();

  useEffect(() => {
    const openModal = counter >= 10;
    if (!openModal) return;
    setCounter(0);
    toggle();
  }, [counter]);

  if (isTabOrPhone)
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height, width, flexDirection: "column" }}
      >
        <img
          src={newLogo}
          style={{ marginBottom: 16, marginTop: 16 }}
          onClick={() => setCounter((p) => p + 1)}
        />
        <div
          style={{
            padding: 16,
            width: isSmall ? "95%" : "80%",
          }}
        >
          {children}
        </div>
        <EnvModal isOpen={isOpen} toggle={toggle} />
      </div>
    );

  return (
    <div style={{ height: "100%", overflowX: "hidden", overflowY: "hidden" }}>
      <Row>
        <Col md={5} style={{ padding: 48, width: "100%" }}>
          <div
            className="d-flex align-items-center justify-content-center h-100 w-100"
            style={{ position: "relative" }}
          >
            {!isLaptop && (
              <img
                src={newLogo}
                style={{
                  position: "absolute",
                  left: 0,
                  top: 0,
                }}
                onClick={() => setCounter((p) => p + 1)}
              />
            )}
            <div className="w-100">{children}</div>
          </div>
        </Col>
        <Col md={7} style={{ height }}>
          <LoginCarousel />
        </Col>
      </Row>
      <EnvModal isOpen={isOpen} toggle={toggle} />
    </div>
  );
};

export const LoginPage = () => (
  <LoginPageComponents>
    <CurrentScreenDecider />
  </LoginPageComponents>
);
