import { useRouter } from "next/router";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Col } from "reactstrap";
import { colors } from "../../assets/colors";
import { CustomButton } from "../../components/Buttons";
import { CustomTextField } from "../../components/inputs";
import {
  GothamMedium,
  GothamRegular,
  TypeFezNeueMedium,
} from "../../components/Text";
import { windowDimension } from "../../components/tools";
import { useMutation } from "../../tools/api";
import { useLoginHook } from "./logics";

export const LoginForm = ({ toRegister, toForgotPassword }) => {
  const { isSmall } = windowDimension();
  const useFormObj = useForm();

  const { loading, login: submit } = useLoginHook();

  const { query } = useRouter();
  const { token } = query;

  const { loading: loadingMutation, mutation } = useMutation({
    url: "/business_users/confirm_email",
    method: "post",
  });

  useEffect(() => {
    if (!token) return;
    mutation({ token });
  }, [token]);

  const { handleSubmit, errors } = useFormObj;

  return (
    <div>
      {!isSmall && (
        <TypeFezNeueMedium
          style={{
            color: colors.blue10,
            fontSize: 24,
            lineHeight: "48px",
            marginBottom: 24,
          }}
        >
          Sign In
        </TypeFezNeueMedium>
      )}
      <form onSubmit={handleSubmit(submit)}>
        <CustomTextField
          name="email"
          useFormObj={useFormObj}
          error={errors.email}
        />
        <CustomTextField
          name="password"
          type="password"
          useFormObj={useFormObj}
          error={errors.password}
        />
        <CustomButton
          isLoading={loading || loadingMutation}
          type="submit"
          style={{
            width: "100%",
          }}
        >
          Sign In
        </CustomButton>
      </form>
      <Col style={{ marginLeft: 0, paddingLeft: 0 }}>
        <GothamRegular style={{ marginTop: 26, color: colors.grey25 }}>
          Don't have an account?
          <span
            className="link"
            style={{
              marginLeft: 5,
              fontWeight: "bold",
              textDecoration: "none",
              fontFamily: "GothamBook",
              fontSize: 14,
            }}
            onClick={() => toRegister && toRegister()}
          >
            Register
          </span>
        </GothamRegular>
        <div style={{ marginTop: 12 }}>
          <span
            style={{
              fontWeight: "bold",
              textDecoration: "none",
              fontFamily: "GothamBook",
              fontSize: 14,
            }}
            className="link"
            onClick={() => toForgotPassword && toForgotPassword()}
          >
            Forgot your password?{" "}
          </span>
        </div>
      </Col>
    </div>
  );
};
